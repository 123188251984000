import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import succes from "../assets/images/icons/success.png";



const SuccessMessage = ({ successMessage, show, onHide }) => {
  return (
    <div>
      {" "}
      <Modal
        show={show}
        onHide={() => onHide}
        className="unity-modal"
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-start">
          <div className="d-flex justify-content-center">
            <img src={succes} alt="none" />
            {/* <IoCloseCircleOutline color="red" size="50" /> */}
          </div>
          {/* <p className="d-flex justify-content-center color-white-II jura m-0">Success</p> */}
          <p className="d-flex justify-content-center color-white jura mt-1">
            {successMessage}
          </p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SuccessMessage;
