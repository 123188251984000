import React, { Suspense, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./assets/css/responsice.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
// import { Login } from "./pages";
import {
  AddEvent,
  AddProjects,
  Applyleave,
  Dashboard,
  Designation,
  Employees,
  EnterTimeSheet,
  Expenses,
  LeaveReq,
  Login,
  LoginTime,
  MyLeaveList,
  MyPermissionL,
  MyTicket,
  PermissionReq,
  Policy,
  RaisedTickets,
  RolePermission,
  Roles,
  TeamType,
  TicketHistory,
  TimeSheetReq,
  ViewProjects,
  ViewTeamDetail,
  TeamStatus,
  Overtime,
  OvertimeReq,
  OvertimeReqView,
  Userprofile,
  UpdateTime,
  MyTimesheet,
  AllPermissionList,
  LeaveType,
  Employeeleave,
  Chartvariable,
  TimesheetReqView,
  TeamExpense,
  ForgotPassword,
  ForgetPassword,
  Request,
  MyLeave,
  UpdateLeave,
  SelectLeave,
  PendingTimesheet,
  ApprovedTimesheet,
  EmployeeLogin,
  TicketHistoryTrail,
  DetailedTimesheet,
  OverTimeCheck,
  Signup,
  
} from "./pages";
import DefaultLayout from "./pages/DefaultLayout";
import Spinner from "react-bootstrap/Spinner";
import Changepsw from "./pages/Changepsw";
import AddNewPassword from "./pages/AddNewPassword";
import Notification from "./pages/Notification";
import "react-datetime/css/react-datetime.css";
import "react-datepicker/dist/react-datepicker.css";

// spinner or Loader
const fallbackLoader = (
  <div className="center">
    <Spinner animation="border" variant="primary" />
  </div>
);
function App() {
  return (
    // App.js
    <Router>
      <Suspense fallback={fallbackLoader}>
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route exact path="/login" element={<Login />} />
          <Route path="/forget/password" element={<ForgetPassword />} />
          {/* <Route path="/login" element={<Signup />} /> */}
          <Route path="/addnewpassword" element={<AddNewPassword />} />

          <Route path="" exact element={<DefaultLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/addevent" element={<AddEvent />} />
            <Route path="timesheet/timesheetreq" element={<TimeSheetReq />} />
            <Route path="timesheet/mytimesheet" element={<MyTimesheet />} />
            <Route
              path="/timesheet/entertimesheet"
              element={<EnterTimeSheet />}
            />
            <Route path="/timesheet/overtime" element={<Overtime />} />
            <Route path="/timesheet/overtimecheck" element={<OvertimeReq />} />
            <Route path="/timesheet/overtimereq" element={<OverTimeCheck />} />
            <Route
              path="/timesheet/overtimereq/view"
              element={<OvertimeReqView />}
            />
            <Route
              path="/timesheet/timesheetreq/view"
              element={<TimesheetReqView />}
            />
            <Route path="/timesheet/pending" element={<PendingTimesheet />} />
            <Route path="/timesheet/approved" element={<ApprovedTimesheet />} />
            <Route
              path="/timesheet/detailed/list"
              element={<DetailedTimesheet />}
            />
            <Route path="/permission/myleavelist" element={<MyLeaveList />} />
            <Route
              path="/permission/mypermissionL"
              element={<MyPermissionL />}
            />
            <Route path="/permission/Chart" element={<Chartvariable />} />

            <Route path="/permission/team-status" element={<TeamStatus />} />
            <Route path="/permission/applyleave" element={<Applyleave />} />
            <Route path="/permission/leavereq" element={<LeaveReq />} />
            <Route
              path="/permission/employeeleave"
              element={<Employeeleave />}
            />

            <Route
              path="/permission/AllPermission"
              element={<AllPermissionList />}
            />
            <Route path="/permission/updateleave" element={<UpdateLeave />} />
            <Route path="/permission/selectleave" element={<SelectLeave />} />
            <Route
              path="/permission/permissionreq"
              element={<PermissionReq />}
            />
            <Route path="/permission/policy" element={<Policy />} />
            <Route path="/myprojects/addprojects" element={<AddProjects />} />
            <Route path="/myprojects/expenses" element={<Expenses />} />
            <Route path="/myprojects/viewproject" element={<ViewProjects />} />
            <Route
              path="/myprojects/view-team-details/:id"
              element={<ViewTeamDetail />}
            />
            <Route path="/myprojects/team/expenses" element={<TeamExpense />} />
            <Route
              path="/mytickets/raisedtickets"
              element={<RaisedTickets />}
            />

            <Route
              path="/mytickets/TicketHistoryTrail"
              element={<TicketHistoryTrail />}
            />
            <Route
              path="/mytickets/tickethistory"
              element={<TicketHistory />}
            />

            <Route path="/addemployee/designation" element={<Designation />} />
            <Route path="/addemployee/employees" element={<Employees />} />
            <Route
              path="/addemployee/rolepermission"
              element={<RolePermission />}
            />
            <Route path="/addemployee/roles" element={<Roles />} />
            <Route path="/addemployee/teamtype" element={<TeamType />} />

            <Route path="/logintime" element={<LoginTime />} />
            <Route path="/employeelogintime" element={<EmployeeLogin />} />
            <Route path="/user-profile" element={<Userprofile />} />
            <Route path="/changepsw" element={<Changepsw />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="/updatetime" element={<UpdateTime />} />
            <Route path="/permission/leavetype" element={<LeaveType />} />
            <Route path="/request" element={<Request />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
}
export default App;
