import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "../Components/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DefaultLayout = () => {
  const navigate = useNavigate();

  return (
    <div className="DefaultLayout h-100">
      <Sidebar />
      <main>
        <Outlet />
      </main>
      <ToastContainer />
    </div>
  );
};

export default DefaultLayout;
