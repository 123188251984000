import { Formik, Field, ErrorMessage } from "formik";
import React, { useEffect, useRef, useState } from "react";
import Api, { getCSRFToken } from "../config/Api";
import { useNavigate } from "react-router-dom";
import { Col, Row, InputGroup, Modal } from "react-bootstrap";
import loginImage from "../assets/images/icons/Login_image.svg";
import Logo from "../assets/images/icons/logo.png";

import Button from "react-bootstrap/Button";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import Form from "react-bootstrap/Form";
import "../css/login.css";
import * as Yup from "yup";
import succes from "../assets/images/icons/success.png";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "../css/forgotpassword.css";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import SuccessMessage from "../Components/SuccessMessage";

const validationSchema = Yup.object().shape({
  newPassword: Yup.string().required("Password is required"),
  //  .min(6, "Password must be at least 6 characters"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const AddNewPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShows, setModalShows] = useState(false);
  const [successMessages, setSuccessMessages] = useState("");

  const updatePassword = async (values) => {
    // Handle form submission
    console.log("valuesabcd", values);
    try {
      const getcsrftoken = await Api.get("/csrf-token");
      const csrfToken = getcsrftoken.data.csrf_token;
      setLoading(true);
      const response = await Api.post("update_password", {
        email: localStorage.getItem("forgetemail"),
        password: values.newPassword,
        _token: csrfToken,
      });

      // Handle response here
      if (response.status == 200) {
        // toast.success("password Changed Successfully !");
        // successMessage();
        setSuccessMessages("Password Reset Successful");
        setModalShows(true);
        setTimeout(() => {
          setModalShows(false);
        }, 1000);
      }
    } catch (error) {
      // Handle errors
      toast.error("New password and current password cannot be same");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="login-container forgot">
        <Row>
          <Col sm={6} className="loginImage">
            <img src={loginImage} />
          </Col>
          <Col sm={6}>
            <div className="d-flex justify-content-center logo-img">
              <img src={Logo} className="logos" />
            </div>
            <Row className="justify-content-center ">
              <Formik
                initialValues={{ newPassword: "", confirmPassword: "" }}
                onSubmit={updatePassword}
                validationSchema={validationSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit} className="login-form">
                    <h2 className="justify-content-center mt-5 jura EMployeetexts">
                      Add New Password
                    </h2>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Group className="pass-eye">
                        {showPassword ? (
                          <span
                            className="eye-login-icon"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <IoIosEye />
                          </span>
                        ) : (
                          <span
                            className="eye-login-icon"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <IoIosEyeOff />
                          </span>
                        )}
                        <Form.Label className="my-0 EMployeetext login-fields-password mt-3">
                          New Password{" "}
                          <span className="text-danger StarICON">*</span>
                        </Form.Label>
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          name="newPassword"
                          className="password-field opacity-100 login-page-form"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.newPassword}
                          placeholder="Enter New Password"
                        />
                        <ErrorMessage
                          component="span"
                          className="text-danger"
                          name="newPassword"
                        />
                      </Form.Group>
                      {/* <Form.Label className="mt-0">New Password</Form.Label>
                      <Form.Control
                            type={showPassword ? "text" : "password"}
                            name="newPassword"
                            className="password-field opacity-100 login-page-form"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.newPassword}
                            placeholder="Enter Password"
                            
                          /> */}
                      {/* <InputGroup>
                        <Form.Control
                          type={!showPassword ? "password" : "text"}
                          name="newPassword"
                          placeholder="Enter Password"
                          className="login-page-form"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.newPassword}
                        />
                        <InputGroup.Text
                          onClick={() => setShowPassword(!showPassword)}
                          style={{ cursor: "pointer" }}
                          className="eye-icon align-items-center login-page-form"
                        >
                          <span className="eye-icons">
                            {showPassword ? (
                              <BsFillEyeFill />
                            ) : (
                              <BsFillEyeSlashFill />
                            )}
                          </span>
                        </InputGroup.Text>
                      </InputGroup> */}
                      {/* <ErrorMessage
                        className="error text-danger"
                        component="p"
                        name="newPassword"
                      /> */}
                    </Form.Group>
                    <Form.Group className="pass-eye">
                      {showPassword ? (
                        <span
                          className="eye-login-icons"
                          onClick={() => setConfirmPassword(!confirmPassword)}
                        >
                          <IoIosEye />
                        </span>
                      ) : (
                        <span
                          className="eye-login-icons"
                          onClick={() => setConfirmPassword(!confirmPassword)}
                        >
                          <IoIosEyeOff />
                        </span>
                      )}
                      <Form.Label className="my-0 EMployeetext login-fields-password mt-2">
                        Confirm Password{" "}
                        <span className="text-danger StarICON">*</span>
                      </Form.Label>
                      <Form.Control
                        type={confirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        className="password-field opacity-100 login-page-form"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmPassword}
                        placeholder="Enter Confirm Password"
                      />
                      <ErrorMessage
                        component="span"
                        className="text-danger"
                        name="confirmPassword"
                      />
                    </Form.Group>
                    {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="mt-0">Confirm Password</Form.Label>
                      <InputGroup className="">
                        <Form.Control
                          type={!confirmPassword ? "password" : "text"}
                          name="confirmPassword"
                          placeholder="Enter Password"
                          className="login-page-form"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmPassword}
                        />
                        <InputGroup.Text
                          onClick={() => setConfirmPassword(!confirmPassword)}
                          style={{ cursor: "pointer" }}
                          className="eye-icon align-items-center login-page-form"
                        >
                          <span className="eye-icons">
                            {confirmPassword ? (
                              <BsFillEyeFill />
                            ) : (
                              <BsFillEyeSlashFill />
                            )}
                          </span>
                        </InputGroup.Text>
                      </InputGroup>
                      <ErrorMessage
                        className="error text-danger"
                        component="p"
                        name="confirmPassword"
                      />
                    </Form.Group> */}
                    <Form.Group className="mt-4">
                      <Button
                        variant="primary"
                        type="submit"
                        className="w-100  p-3 form-button jura fw-bolder"
                      >
                        SUBMIT
                      </Button>
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            </Row>
          </Col>
        </Row>

        <SuccessMessage
          successMessage={successMessages}
          show={modalShows}
          onHide={() => setModalShows(false)}
        />
      </div>
    </>
  );
};

export default AddNewPassword;
