import React, { useEffect, useState } from "react";
import policy from "../assets/images/icons/insurance.png";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons";
import menubar from "../assets/images/icons/menu-bar.svg";
import avathar from "../assets/images/icons/avathar.png";
import Logo from "../assets/images/icons/logo.png";
import { Dropdown, Button, Form, InputGroup, Row } from "react-bootstrap";
import signout from "../assets/images/icons/logout-blue.svg";
import cpsw from "../assets/images/icons/key-blue.svg";
import profile from "../assets/images/icons/profile-blue.svg";
import signout2 from "../assets/images/icons/logout-green.svg";
import cpsw2 from "../assets/images/icons/key-green.svg";
import profile2 from "../assets/images/icons/profile-green.svg";
import Line from "../assets/images/icons/sharpline.svg";
import { RxHamburgerMenu } from "react-icons/rx";

import "../css/sidebar.css";
import Avatar from "react-avatar";
import Api, { getAPI_RESPONSE, getCSRFToken } from "../config/Api";
import { RxDash } from "react-icons/rx";
import dashboard from "../assets/images/icons/Menu Dashboard.svg";
import login from "../assets/images/icons/Menu Login time.svg";
import timesheet from "../assets/images/icons/Menu Timesheet.svg";
import project from "../assets/images/icons/Menu My projects.svg";
import ticket from "../assets/images/icons/Menu Raise Tickets.svg";
import employees from "../assets/images/icons/Menu Add employees.svg";
import event from "../assets/images/icons/Menu Add events.svg";
import leave from "../assets/images/icons/permission.svg";
import updateLeave from "../assets/images/icons/update.png";
import { MdKeyboardArrowDown } from "react-icons/md";
import updatetime from "../assets/images/icons/updateTime.svg";
import bell from "../assets/images/icons/notification bell.svg";
import Modal from "react-bootstrap/Modal";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { ErrorMessage, Formik } from "formik";
import "../css/menu.css";
import "../pages/Permission/leave.css";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import succes from "../assets/images/icons/success.png";
import { GoDotFill } from "react-icons/go";
import redDot from "../assets/images/icons/red-dot.svg";

const Nav = styled.div`
  background: black;
  display: flex;
  overflow: hidden;
  font-family: "Oxanium", sans-serif;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  width: 100%;
`;

const NavIcon = styled(Link)`
  margin-left: 1rem;
  font-size: 1rem;
  font-family: "Oxanium", sans-serif;
  display: flex;
  background: black;
  height: 50px;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: #1a1c24;
  overflow: scroll;
  display: flex;
  width: 235px;
  font-family: "Oxanium", sans-serif;
  height: 100%;
  justify-content: center;
  border-top-right-radius: 8px;
  position: fixed;
  margin-top: 70px;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;
const Sidebar = () => {
  const [sidebar, setSidebar] = useState("true");
  const [permission, setPermission] = useState(false);
  const token = localStorage.getItem("token") || null;
  const showSidebar = () => setSidebar(!sidebar);
  const Navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(null);
  const [PermissionArray, setPermissionArray] = useState([]);
  const userId = localStorage.getItem("userId");
  const [employee, setEmployee] = useState();
  const [listen, setlisten] = useState(false);
  const [pageName, setPageName] = useState([]);
  const [pageStatus, setPageStatus] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [showPasswordCurrent, setShowPasswordCurrent] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [eventPendingList, SetEventPendingList] = useState([]);
  const [leavePendingList, SetleavePendingList] = useState([]);
  const [perPendingList, SetperPendingList] = useState([]);
  const roleId = localStorage.getItem("role_id");
  const bearerToken = localStorage.getItem("token");
  const [profileImage, setprofileImage] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [notificationList, SetnotificationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [unreadCount, setUnreadCount] = useState([]);
  const [PendingCount, setPendingCount] = useState([]);
  const [policyCount, setPolicyCount] = useState(false);
  const [names, setnames] = useState([]);
  const [empId, setEmpId] = useState(
    localStorage.getItem("employeeId") || null
  );

  const handleKeyDown = (event) => {
    if (event.keyCode === 27) {
      // 27 is the key code for the escape key
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const successMessage = () => {
    setSuccessShow(true);
    setTimeout(() => {
      setSuccessShow(false);
    }, 1000);
  };
  const logout = () => {
    localStorage.clear(Navigate("/login"));
  };
 
  const submitForm = async (values) => {
    console.log("valuespassword", values);
    try {
      // Fetch CSRF token
      const csrfToken = await getCSRFToken();
      // Make login API request
      const response = await Api.post(
        `/change_password`,
        {
          current_password: values.currentPassword,
          new_password: values.newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // "X-CSRF-TOKEN": csrfToken,
          },
        }
      );

      if (response?.status === 200) {
        console.log("response", response);
        successMessage();
        setModalShow(false);
      } else if (response.status == 400) {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
    }
  };
  const id = localStorage.getItem("id");
  useEffect(() => {
    
    getuser()
  }, []);

  useEffect(() => {
    if (permission) {
      const permissions = permission.map((permission) => permission.permission);
      setPermissionArray(permissions);
    }
  }, [permission]);

  //  employee api call


  const MINUTE_MS = 60000;

  const getEmployee = () => {
    try {
      const x = localStorage.getItem("loggeduser_data");
      const userdata = JSON.parse(x);
      setEmployee(userdata.user);
      localStorage.setItem("profile-image", userdata.user.avatar);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setTimeout(() => {
          window.location.href = "/login";
        }, 0);
        return;
      } else {
        console.error("Error fetching employee data:", error);
      }
    }
  };

  function getPermission() {
    const application_page_names = JSON.parse(
      localStorage.getItem("application_page_names")
    );
    const application_page_status = JSON.parse(
      localStorage.getItem("application_page_status")
    );
    const application_page_permission = JSON.parse(
      localStorage.getItem("application_page_permission")
    );
    setPageName(application_page_names);
    setPageStatus(application_page_status);
    setPermission(application_page_permission);
  }

  // const checkLoginStatus = () => {
  //   console.log("vishal");

  //   const now = Date.now();
  //   console.log("loginTime && now - loginTime", loginTime && now - loginTime);
  //   if (loginTime && now - loginTime > 3600000) {
  //     toast.error("Session Timeout");
  //     localStorage.removeItem("loginTime");
  //     logout();
  //   }
  // };


  // GetAccount Name 

  const getuser = async () => {
    try {
      // Fetch CSRF token
      // Make login API request
      const response = await Api.get(`/get_user/${empId}`);

      if (response?.status === 200) {
        setnames(response.data.data)
      } else if (response.status == 400) {
        setnames([])
        toast.error(response.message);
      }
    } catch (error) {
      setnames([])
      toast.error(error?.response?.data?.error);
    }
  };


  // GetAccount Name 

  const [activeButton, setActiveButton] = useState("", "dashboard");

  function handleClick(event) {
    const toValue = event.currentTarget.getAttribute("href");

    setActiveButton(toValue);
  }

  useEffect(() => {
    const activepageName = window.location.pathname;
    console.log("activepageName", activepageName);
    setActiveButton(activepageName);

    const timesheetmenu = [
      "/timesheet/entertimesheet",
      "/timesheet/overtime",
      "/timesheet/detailed/list",
    ];
    const ticketmenu = ["/mytickets/raisedtickets", "/mytickets/tickethistory"];
    const logtimemenu = ["/logintime", "/employeelogintime"];

    const leavemenu = [
      "/Permission/applyleave",
      "/Permission/team-status",
      "/Permission/myleavelist",
      "/Permission/leavereq",
      "/Permission/mypermissionl",
      "/Permission/permissionreq",
      "/Permission/policy",
    ];
    const employeemenu = [
      "/AddEmployee/Roles",
      "/AddEmployee/RolePermission",
      "/AddEmployee/TeamType",
      "/AddEmployee/Designation",
      "/AddEmployee/Employees",
    ];
    const projectMenu = [
      "/myprojects/addprojects",
      "/myprojects/viewproject",
      "/myprojects/expenses",
      "/myprojects/team/expenses",
    ];
    let menu = "";
    if (timesheetmenu.includes(activepageName)) {
      menu = "timesheet";
    } else if (leavemenu.includes(activepageName)) {
      menu = "leave";
    } else if (projectMenu.includes(activepageName)) {
      menu = "project";
    } else if (employeemenu.includes(activepageName)) {
      menu = "employee";
    } else if (logtimemenu.includes(activepageName)) {
      menu = "logtime";
    }

    setOpenMenu(openMenu === menu ? null : menu);
  }, []);

  const handleMenuClick = (menu) => {
    setOpenMenu(openMenu === menu ? null : menu);
  };
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required("Current password is required"),
    newPassword: Yup.string().required("New password is required"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.newPassword === value;
      }),
  });



  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <nav className="navbar navbar-expand-sm mt-0 unity-header">
          <div className="container-fluid">
            <div
              className="collapse navbar-collapse "
              id="navbarSupportedContent"
            >
              <div className="">
                <RxHamburgerMenu
                  className="ms-3 hamburger"
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                />

                <a
                  className="navbar-brand ms-3"
                  href="#"
                  onClick={() => {
                    Navigate("/dashboard");
                  }}
                >
                  <img src={Logo} className="Logo" />
                </a>
              </div>

              <div className="d-flex ms-auto order-5 align-items-center mobile-profile">
                <Dropdown className="spassov-Toggle" >
                  {/* <Dropdown.Toggle className="spassov-click">
                    <div class="spassovbell" id="bell-1">
                      <img
                        src={bell}
                        className="mx-2 anchor material-icons layer-1"
                      />
                      <img
                        src={bell}
                        className="mx-2 anchor material-icons layer-2"
                      />
                      <img
                        src={bell}
                        className="mx-2 anchor material-icons layer-3"
                      />
                      <span id="bellCount" class="bellcount">
                        15
                      </span>
                    </div>
                  </Dropdown.Toggle> */}

                  <Dropdown.Menu className="spassov-dropdown " >
                    <div className="row notification-header">
                      <div className="col-6">Notifications</div>
                      <div className="col-6 View_all">
                        <a
                          href=""
                          className="View_all-ag"
                          onClick={() => {
                            Navigate("/notification");
                          }}
                        >
                          View All
                        </a>{" "}
                      </div>
                    </div>

                    <div className="row notify-box">
                      <div className="mainboX">
                        <div className="col-1">
                          {" "}
                          <img src={bell} className="mx-2" />
                        </div>
                        &nbsp;
                        <div className="col-8">
                          {" "}
                          <p className="textmain-box">Permission </p>
                          <p className="textcont">
                            An employee is entitled for 4 hours permission..
                          </p>
                        </div>
                        <div className="col-3">
                          {" "}
                          <p className="text-time">2 mins Ago</p>
                        </div>
                      </div>
                    </div>
                    <div className="row notify-box">
                      <div className="mainboX">
                        <div className="col-1">
                          {" "}
                          <img src={bell} className="mx-2" />
                        </div>
                        &nbsp;
                        <div className="col-8">
                          {" "}
                          <p className="textmain-box">Permission </p>
                          <p className="textcont">
                            An employee is entitled for 4 hours permission..
                          </p>
                        </div>
                        <div className="col-3">
                          {" "}
                          <p className="text-time">2 mins Ago</p>
                        </div>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>

                <label className="account_name">{`${employee?.name} ${employee?.last_name}`}</label>

                <Dropdown className="">
                  <Dropdown.Toggle className="sandwich" id="dropdown-basic">
                    <Avatar
                      size="40"
                      round={true}
                      src={
                        employee
                          ? "https://mytimesheet.s3.ap-south-1.amazonaws.com/" +
                            profileImage
                          : ""
                      }
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="profile-dropdown">
                    <>
                      <Dropdown.Item
                        onClick={() => Navigate("/user-profile")}
                        className="profile-drop"
                      >
                        <img src={profile} alt="" />
                        <img src={profile2} alt="" />
                        <span className="mx-2"> View Profile</span>
                      </Dropdown.Item>
                      <img
                        src={Line}
                        alt=""
                        className="mx-auto w-100 profile-drop-line"
                      />
                    </>

                    <Dropdown.Item onClick={() => logout()} className="">
                      <img src={signout} alt="" />
                      <img src={signout2} alt="" />
                      <span className="mx-2"> Logout</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="d-flex ms-auto order-5 align-items-center desktop-profile">
                <Dropdown className="spassov-Toggle">
                  <Dropdown.Toggle className="spassov-click">
                    <div className="spassovbell" id="bell-1">
                     
                     

                      {unreadCount > 0 && (
                        <span id="bellCount" className="bellcount">
                          {unreadCount}
                        </span>
                      )}
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="spassov-dropdown">
                    <div className="row notification-header">
                      <div className="col-6">Notifications</div>
                      <div className="col-6 View_all">
                        <a
                          href=""
                          className="View_all-ag"
                          onClick={() => {
                            Navigate("/notification");
                          }}
                        >
                          View All
                        </a>{" "}
                      </div>
                    </div>
                    {notificationList.slice(0, 5).map((notification, index) => (
                      <div
                        className="row notify-box"
                        key={notification.id || index}
                      >
                        <div className="mainboX">
                          <div className="col-1">
                            <img src={bell} className="mx-2" />
                          </div>
                          &nbsp;
                          <div className="col-6">
                            <p className="textmain-box">{notification.title}</p>
                            <p className="textcont">{notification.message}</p>
                          </div>
                          <div className="col-5">
                            <p className="text-time">
                              {notification.created_at}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

               
           

                <label className="account_name">{ names.length > 0 && names.map((value)=>value.name)}</label>

                <Dropdown className="">
                  <Dropdown.Toggle className="sandwich" id="dropdown-basic">
                    <img src={menubar} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="profile-dropdown ">
                    <Dropdown.Item
                      onClick={() => Navigate("/user-profile")}
                      className="profile-drop d-none"
                    >
                      <img src={profile} alt="" />
                      <img src={profile2} alt="" />
                      <span className="mx-2"> View Profile</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => logout()} className="">
                      <img src={signout} alt="" />
                      <img src={signout2} alt="" />
                      <span className="mx-2"> Logout</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </nav>
        <SidebarNav
          sidebar={sidebar}
          className={`${sidebarOpen ? `sidebar-main open` : `sidebar-main`}`}
        >
         <div className="w-100">
         <Link
            className={`${activeButton === "/dashboard" ? "active" : ""}`}
            onClick={(e) => {
              handleClick(e);
              handleMenuClick("dashboard");
            }}
            to="/dashboard"
          >
            <div className="w-100"
              onClick={() => {
                setSidebarOpen(false);
              }}
            >
              <img src={dashboard} className="menu-icon" />
              <span className="sc-guDLey fMqvoF">Dashboard</span>
            </div>
            <div></div>
          </Link>
         </div>
        </SidebarNav>
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          className="unity-modal changepsw"
          onEscapeKeyDown={handleKeyDown}
          size="md"
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="text-start d-block"
            >
              Change Password
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-start">
            <Formik
              initialValues={{
                currentPassword: "",
                newPassword: "",
                confirmPassword: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => {
                submitForm(values, { resetForm });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {/* <Form.Group className="pass-eye">
                    {showPasswordCurrent ? (
                      <span className="eye" onClick={() => setview(false)}>
                        <IoIosEye />
                      </span>
                    ) : (
                      <span className="eye" onClick={() => setview(true)}>
                        <IoIosEyeOff />
                      </span>
                    )}
                    <Form.Label className="mb-1 EMployeetext">
                      Password <span className="text-danger StarICON">*</span>
                    </Form.Label>
                    <Form.Control
                      type={`${view ? "text" : "password"}`}
                      name="currentPassword"
                      className="password-field opacity-100"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.currentPassword}
                      placeholder="Enter Current Password"
                      
                    />
                    <ErrorMessage
                      component="span"
                      className="text-danger"
                      name="password"
                    />
                  </Form.Group> */}
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label className="mt-0">
                      Enter Your Current Password
                    </Form.Label>

                    <InputGroup>
                      <Form.Control
                        type={showPasswordCurrent ? "text" : "password"}
                        name="currentPassword"
                        placeholder="Enter Password"
                        className="login-page-form"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.currentPassword}
                      />
                      <InputGroup.Text
                        onClick={() =>
                          setShowPasswordCurrent(!showPasswordCurrent)
                        }
                        style={{ cursor: "pointer" }}
                        className="eye-icon2 align-items-center"
                      >
                        {showPasswordCurrent ? (
                          <BsFillEyeFill />
                        ) : (
                          <BsFillEyeSlashFill />
                        )}
                      </InputGroup.Text>
                    </InputGroup>
                    <ErrorMessage
                      className="error text-danger"
                      component="span"
                      name="currentPassword"
                    />
                  </Form.Group>
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label className="mt-0">New Password</Form.Label>

                    <InputGroup>
                      <Form.Control
                        type={showPasswordNew ? "text" : "password"}
                        name="newPassword"
                        placeholder="Enter Password"
                        className="login-page-form"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.newPassword}
                      />
                      <InputGroup.Text
                        onClick={() => setShowPasswordNew(!showPasswordNew)}
                        style={{ cursor: "pointer" }}
                        className="eye-icon2 align-items-center"
                      >
                        {showPasswordNew ? (
                          <BsFillEyeFill />
                        ) : (
                          <BsFillEyeSlashFill />
                        )}
                      </InputGroup.Text>
                    </InputGroup>
                    <ErrorMessage
                      className="error text-danger"
                      component="span"
                      name="newPassword"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="mt-0">Confirm Password</Form.Label>
                    <InputGroup className="">
                      <Form.Control
                        type={showPasswordConfirm ? "text" : "password"}
                        name="confirmPassword"
                        placeholder="Enter Password"
                        className="login-page-form"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmPassword}
                      />
                      <InputGroup.Text
                        onClick={() =>
                          setShowPasswordConfirm(!showPasswordConfirm)
                        }
                        style={{ cursor: "pointer" }}
                        className="eye-icon2 align-items-center"
                      >
                        {showPasswordConfirm ? (
                          <BsFillEyeFill />
                        ) : (
                          <BsFillEyeSlashFill />
                        )}
                      </InputGroup.Text>
                    </InputGroup>
                    <ErrorMessage
                      className="error text-danger"
                      component="span"
                      name="confirmPassword"
                    />
                  </Form.Group>

                  <div className="d-flex justify-content-center mb-4 row">
                    <Button
                      className="w-25 mx-3"
                      variant="outline-danger btn-outline-danger btn-dark-red"
                      onClick={() => {
                        setModalShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit update"
                      className="w-25"
                      variant="outline-success btn-outline-success1 btn-dark-green"
                    >
                      Update
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </IconContext.Provider>
      <Modal
        show={successShow}
        onHide={() => setSuccessShow(false)}
        className="unity-modal"
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-start">
          <div className="d-flex justify-content-center">
            <img src={succes} alt="none" />
          </div>
          <p className="d-flex justify-content-center color-white-II jura m-0">
            Success
          </p>
          <p className="d-flex justify-content-center color-white jura mt-1">
            Password Changed Successfully
          </p>
        </Modal.Body>
      </Modal>
      <div
        className={`${sidebarOpen ? `black-overlay` : `black-overlay d-none`}`}
        onClick={() => setSidebarOpen(false)}
      ></div>
    </>
  );
};

export default Sidebar;
