import {
  Form,
  Button,
  Row,
  Col,
  InputGroup,
  Dropdown,
  FormControl,
} from "react-bootstrap";
import "../pages/MyProjects/myproject.css";
import "../pages/Permission/leave.css";
import "../pages/MyTickets/ticket.css";
import "../pages/AddEmployee/addemployee.css";
import backArrow from "../../src/assets/images/icons/back-arrow.svg";
import permissionNotify from "../assets/images/icons/permission-notify.svg";
import bell from "../assets/images/icons/notification bell.svg";
import "./notification.css";
import "../css/sidebar.css";
import permission from "../assets/images/icons/permission-notify.svg";
import { Link, useNavigate } from "react-router-dom";
import Api, { getAPI_RESPONSE, getCSRFToken } from "../config/Api";
import { React, useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import NotificationPagination from "../Components/NotificationPagination";
import UnityPagination from "../Components/UnityPagination";




const Notification = () => {

  const [notificationList, SetnotificationList] = useState([]);
  const [readNotification, setReadNotification] = useState([]);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [readallloading, setReadLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const Navigate = useNavigate();

const getAllReadMessage = async () => {
  const csrfToken = await getCSRFToken();

  setReadLoading(true);
  const response = await Api.post(
    "/edit_notify_status",
    {}, 
    {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    }
  );

  console.log("response readall", response);

  if (response.status === 200) {
    const data = response.data;
    setReadNotification(data);
    toast.success(data.message || "Success", {
      theme: "dark",
    });
  }
  
  setReadLoading(false);
};

const getUnread = async () => {
  try {
    const csrfToken = await getCSRFToken();

    setLoading(true);
    const response = await Api.post("/notification", {}, {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    });

    // Ensure the response data is an array
    const data = response.data
    console.log("response", data);

    SetnotificationList(data.notifications);
    console.log("responsedfgfdsasd", data);
    
    setCurrentPage(data.current_page);
    setLastPage(data.last_page);
    
  } catch (error) {
    console.error("Project Data" + error);
    if (error.response && error.response.status === 401) {
      window.location.href = "/login";
    }
  } finally {
    setLoading(false); // Ensure loading state is reset
  }
};
const handlePageChange = (page) => {
  setCurrentPage(page);
};
  useEffect(() => {
    getUnread();
  }, []);
  return (
    <>
      <div className="add-emp tickets">
        <Row className="d-flex justify-content-between">
          <Col sm={10} className="d-flex align-items-center">
            <img src={backArrow} 
             onClick={() => {
                    Navigate("/dashboard");
                  }} className="backarrow"/>
            <h4 className="color-blue2 jura mvp mt-1">Notification</h4>
          </Col>
          <Col sm={2}>
          <p className="color-blue2 jura cursor-pointer" onClick={() => getAllReadMessage()}> <img src={bell} alt="" /> Mark all as read</p>
          </Col>
        </Row>
        <br></br>
        {/* {readNotification.map((markall, index) => (
           <div className="markreadall">
           <p className="color-white"></p>
         </div>
        ))} */}

        {notificationList.map((notification, index) => (
        <div className={`row notify-box justify-content-between pt-3 ${notification.status === 0 ? 'bg-gray' : ''}`}>
      
                        <div className="col-10 d-flex">
                        <img src={permission} className="notify-icon mx-1 w-auto menu-icon" />
                        <div>
                        <p className="title inter">{notification.title}
                        <span className="notify"></span>
                         </p>
                          <p className="notify-para">
                          {notification.message}
                          </p>
                        </div>
                        </div>
                        <div className="col-2">
                          <p className="">{notification.created_at}</p>
                        </div>
                 
      </div>
           ))}
          <div className="mt-1">
          {notificationList.length > 0 && (
          <NotificationPagination
            totalPages={lastPage}
            setCurrentPage={handlePageChange}
            currentPage={currentPage}
          />
        )}
          </div>
    </div>
    
 </>
  );
};

export default Notification;
