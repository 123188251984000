// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fMqvoF{
    font-family: 'Oxanium',sans-serif;
}
.jTlarG:hover{
    border-top: none !important;
    border-bottom: none !important;
    border-left: 6px solid #0057FF !important;
}`, "",{"version":3,"sources":["webpack://./src/css/menu.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;AACrC;AACA;IACI,2BAA2B;IAC3B,8BAA8B;IAC9B,yCAAyC;AAC7C","sourcesContent":[".fMqvoF{\n    font-family: 'Oxanium',sans-serif;\n}\n.jTlarG:hover{\n    border-top: none !important;\n    border-bottom: none !important;\n    border-left: 6px solid #0057FF !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
