// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.para {
  font-weight: normal;
  color: #a8b3bb !important;
}
.head-title {
  font-weight: 600;
}
.otp-placeholder {
  width: 100px;
  height: 40px;
}
.otp-placeholder:hover {
  background-color: #101424 !important;
  border-color: #324470 !important;
  color: #fff !important;
}
.otp-placeholder:focus-visible {
  border: none !important;
}

.login-page-form1 {
  background-color: #101424;
  border: 1px solid#324470;
  padding: 10px;
  color: #fff;
}
.login-page-form1:focus {
  background-color: #1b2f55;
  color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
}
.login-page-form1:focus-visible {
  background-color: #1b2f55;
  color: #fff;
  border-bottom: #4ebdf2 solid !important;
}
.resend {
  text-align: end;
  color: white;
  background: none;
  border: none;
}
.back {
  color: #52C8FF;
  text-align: center;
  font-family: "Inter", sans-serif;
  /* font: ; */
}
@media screen and (max-width: 768px) {
  .para {
    text-wrap: nowrap;
    font-size: 8px;
  }
  .otp-placeholder {
    width: 34px;
    height: 40px;
}
.resend{
  font-size: 10px;
}
}
`, "",{"version":3,"sources":["webpack://./src/css/forgotpassword.css"],"names":[],"mappings":"AAGA;EACE,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,YAAY;AACd;AACA;EACE,oCAAoC;EACpC,gCAAgC;EAChC,sBAAsB;AACxB;AACA;EACE,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,wBAAwB;EACxB,aAAa;EACb,WAAW;AACb;AACA;EACE,yBAAyB;EACzB,WAAW;EACX,qBAAqB;EACrB,UAAU;EACV,8CAA8C;AAChD;AACA;EACE,yBAAyB;EACzB,WAAW;EACX,uCAAuC;AACzC;AACA;EACE,eAAe;EACf,YAAY;EACZ,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,gCAAgC;EAChC,YAAY;AACd;AACA;EACE;IACE,iBAAiB;IACjB,cAAc;EAChB;EACA;IACE,WAAW;IACX,YAAY;AAChB;AACA;EACE,eAAe;AACjB;AACA","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap\");\n\n\n.para {\n  font-weight: normal;\n  color: #a8b3bb !important;\n}\n.head-title {\n  font-weight: 600;\n}\n.otp-placeholder {\n  width: 100px;\n  height: 40px;\n}\n.otp-placeholder:hover {\n  background-color: #101424 !important;\n  border-color: #324470 !important;\n  color: #fff !important;\n}\n.otp-placeholder:focus-visible {\n  border: none !important;\n}\n\n.login-page-form1 {\n  background-color: #101424;\n  border: 1px solid#324470;\n  padding: 10px;\n  color: #fff;\n}\n.login-page-form1:focus {\n  background-color: #1b2f55;\n  color: #fff;\n  border-color: #86b7fe;\n  outline: 0;\n  box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);\n}\n.login-page-form1:focus-visible {\n  background-color: #1b2f55;\n  color: #fff;\n  border-bottom: #4ebdf2 solid !important;\n}\n.resend {\n  text-align: end;\n  color: white;\n  background: none;\n  border: none;\n}\n.back {\n  color: #52C8FF;\n  text-align: center;\n  font-family: \"Inter\", sans-serif;\n  /* font: ; */\n}\n@media screen and (max-width: 768px) {\n  .para {\n    text-wrap: nowrap;\n    font-size: 8px;\n  }\n  .otp-placeholder {\n    width: 34px;\n    height: 40px;\n}\n.resend{\n  font-size: 10px;\n}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
