// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notify-box{
    border-bottom: 1px solid #5f5f5f;
    margin: 0;
    color: gray;
}
.title{
    color: white;
    font-weight: 500;
    font-family: inter;
    font-size: 19px;
}
.notify-para{
    margin-top: -14px;

}
.notify-time{
    color: gray;
}
.notify-icon{
    height: 30px !important;
    width: 30px !important;
}
.bg-gray {
    background-color: rgb(87, 87, 87) !important;
    border-radius: 8px;
    color: lightgray;
    margin-top: 5px;
}
.backarrow{
    width: 20px;
    /* margin-top: -10px; */
}
.mark{
    cursor: pointer;
    background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/pages/notification.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,SAAS;IACT,WAAW;AACf;AACA;IACI,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,iBAAiB;;AAErB;AACA;IACI,WAAW;AACf;AACA;IACI,uBAAuB;IACvB,sBAAsB;AAC1B;AACA;IACI,4CAA4C;IAC5C,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,WAAW;IACX,uBAAuB;AAC3B;AACA;IACI,eAAe;IACf,6BAA6B;AACjC","sourcesContent":[".notify-box{\n    border-bottom: 1px solid #5f5f5f;\n    margin: 0;\n    color: gray;\n}\n.title{\n    color: white;\n    font-weight: 500;\n    font-family: inter;\n    font-size: 19px;\n}\n.notify-para{\n    margin-top: -14px;\n\n}\n.notify-time{\n    color: gray;\n}\n.notify-icon{\n    height: 30px !important;\n    width: 30px !important;\n}\n.bg-gray {\n    background-color: rgb(87, 87, 87) !important;\n    border-radius: 8px;\n    color: lightgray;\n    margin-top: 5px;\n}\n.backarrow{\n    width: 20px;\n    /* margin-top: -10px; */\n}\n.mark{\n    cursor: pointer;\n    background-color: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
