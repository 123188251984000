import axios from "axios";
import { useHistory } from 'react-router-dom';
const BaseURL = "https://backend.hrms.utwebapps.com/public/api";
//  const BaseURL = "http://localhost/timesheet_backend/public/api";
// const BaseURL = "http://localhost/HRMS-backend/public/api";
const Api = axios.create({
  baseURL: BaseURL,
});
//api csrf token
export const getCSRFToken = async () => {
  try {
    // const response = await Api.get('/csrf-token');
    const response = localStorage.getItem("csrfToken")
    return response;
  } catch (error) {
    console.error('Error fetching CSRF token:', error);
    if (error.response && error.response.status === 401) {
      setTimeout(() => {
        window.location.href = "/login";
      }, 0);
      return; 
    }
  }
};
export const getAPI_RESPONSE = async (url, reqdata, method) => {
  const token = localStorage.getItem("token");
  var response ;
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (reqdata) {
      config = {
        ...config,
        data: reqdata,
      };
      response = await Api[method](url, reqdata, config); // Pass config as the third argument
    } 
    else {
      response = await Api[method](url, config); // Pass config as the third argument
      if (response && response.status === 401) {
        setTimeout(() => {
          window.location.href = "/login";
        }, 0);
        return; 
      } 
    }
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401) {
      alert("UNAUTHORIZED");
        setTimeout(() => {
          window.location.href = "/login";
        }, 0);
        return; 
      } 
    else {
      alert("Something went wrong.... contact admin");
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      setTimeout(() => {
        window.location.href = "/login";
      }, 0);
      return; 
    } 
    console.error("Error fetching unread notifications:", error);
  }
  
};
export default Api;