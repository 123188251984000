import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import dashboard from "../assets/images/icons/Menu Dashboard.svg";
import login from "../assets/images/icons/Menu Login time.svg";
import timesheet from "../assets/images/icons/Menu Timesheet.svg";
import project from "../assets/images/icons/Menu My projects.svg";
import ticket from "../assets/images/icons/Menu Raise Tickets.svg";
import employee from "../assets/images/icons/Menu Add employees.svg";
import event from "../assets/images/icons/Menu Add events.svg";
import leave from "../assets/images/icons/permission.svg";
import updatetime from "../assets/images/icons/updateTime.svg"
import { BsDash } from "react-icons/bs";


export const SidebarData = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: <img src={dashboard} className='menu-icon' />
  },

  {
    title: 'Log Time',
    path: '/logintime',
    icon: <img src={login} className='menu-icon' />,

    subNav: [
      {
        title: 'Login Time',
        path: '/logintime',
        icon: <BsDash />,
        cName: 'sub-nav'
      },
      {
        title: 'Employee Login Time',
        path: '/employeelogintime',
        icon: <BsDash />,
        cName: 'sub-nav'
      }
    ]
  },

  {
    title: 'Time Sheet',
    path: '/timesheet',
    icon: <img src={timesheet} className='menu-icon' />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Enter Time Sheet',
        path: '/timesheet/entertimesheet',
        icon: <BsDash />,
        cName: 'sub-nav'
      },
      {
        title: 'Time Sheet Request',
        path: '/timesheet/timesheetreq',
        icon: <BsDash />,
        cName: 'sub-nav'
      },
      {
        title: 'Overtime',
        path: '/timesheet/overtime',
        icon: <BsDash />,
        cName: 'sub-nav'
      },
      {
        title: 'Overtime Request',
        path: '/timesheet/overtimereq',
        icon: <BsDash />,
        cName: 'sub-nav'
      }
    ]
  },
  {
    title: 'Leave/ Permission',
    path: '/permission',
    icon: <img src={leave}  className='menu-icon ' />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Team Status',
        path: '/Permission/team-status',
        icon: <BsDash />,
        cName: 'sub-nav'
      },
      {
        title: 'Apply Leave',
        path: '/Permission/applyleave',
        icon: <BsDash />,
        cName: 'sub-nav'
      },
      
      {
        title: 'My Leave List',
        path: '/Permission/myleavelist',
        icon: <BsDash />,
        cName: 'sub-nav'
      },
      {
        title: 'Leave Requests',
        path: '/Permission/leavereq',
        icon: <BsDash />,
        cName: 'sub-nav'
      },

      {
        title: 'My Permission List',
        path: '/Permission/mypermissionl',
        icon: <BsDash />,
        cName: 'sub-nav'
      },
      {
        title: 'Permission Request',
        path: '/Permission/permissionreq',
        icon: <BsDash />,
        cName: 'sub-nav'
      },
      {
        title: 'Policy',
        path: '/Permission/policy',
        icon: <BsDash />,
        cName: 'sub-nav'
      }
    ]
  },
  {
    title: 'My Projects',
    path: '/myprojects',
    icon: <img src={project} className='menu-icon' />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Add Projects',
        path: '/myprojects/addprojects',
        icon: <BsDash />,
        cName: 'sub-nav'
      },
      {
        title: 'View Projects',
        path: '/myprojects/viewproject',
        icon: <BsDash />,
        cName: 'sub-nav'
      },
      {
        title: 'Expenses',
        path: '/myprojects/expenses',
        icon: <BsDash />,
        cName: 'sub-nav'
      }
    ]
  },
  {
    title: 'My Tickets',
    path: '/mytickets',
    icon: <img src={ticket} className='menu-icon' />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Raised Tickets',
        path: '/mytickets/raisedtickets',
        icon: <BsDash />,
        cName: 'sub-nav'
      },
      {
        title: 'Ticket History',
        path: '/mytickets/tickethistory',
        icon: <BsDash />,
        cName: 'sub-nav'
      }
    ]
  },
  {
    title: 'Add Employee',
    path: '/addemployee',
    icon: <img src={employee} className='menu-icon' />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Roles',
        path: '/addemployee/roles',
        icon: <BsDash />,
        cName: 'sub-nav'
      },
      {
        title: 'Role Permission',
        path: '/addemployee/rolepermission',
        icon: <BsDash />,
        cName: 'sub-nav'
      },
      {
        title: 'Team Type',
        path: '/addemployee/teamtype',
        icon: <BsDash />,
        cName: 'sub-nav'
      },
      {
        title: 'Designation',
        path: '/addemployee/designation',
        icon: <BsDash />,
        cName: 'sub-nav'
      },
      {
        title: 'Employees',
        path: '/addemployee/employees',
        icon: <BsDash />,
        cName: 'sub-nav'
      }
    ]
  },
  {
    title: 'Add Event',
    path: '/addevent',
    icon: <img src={event} className='menu-icon' />,
  },
  {
    title: 'Update Time',
    path: '/updatetime',
    icon: <img src={updatetime} className='menu-icon' />,
  }
];