import React from 'react';
import { Pagination, Stack } from 'react-bootstrap';

const NotificationPagination = ({ totalPages, setCurrentPage, currentPage, total, shown,totalRecords }) => {
  // Calculate the indices for displaying records on the current page
  const recordsPerPage = 8;
  const startIndex = (currentPage - 1) * recordsPerPage + 1; 
  const endIndex = Math.min(currentPage * recordsPerPage); 
  const intermediateIndex = endIndex - (8 - total) ;

  const items = [];
  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => setCurrentPage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <Stack spacing={2} className="PaginationMain">
      <div className="row">
        <div className="col-lg-6 col-sm-12 RecordsText">
          <span className="RecordsTextspan text-white">
            {console.log("totalRecords", total)}
            Showing {startIndex} to {endIndex} of {endIndex} entries
          </span>
        </div>
        <div className="col-lg-6 col-sm-12 d-flex justify-content-end">
        <Pagination>
            <Pagination.Prev 
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {totalPages > 0 ? items : <Pagination.Item disabled>No Pages</Pagination.Item>}
            <Pagination.Next 
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </div>
      </div>
    </Stack>
  );
};

export default NotificationPagination;
