import React from "react";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

function UnityPagination({
  totalPages,
  currentPage,
  setCurrentPage,
  totRecord,
  totalRecords,
}) {
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  

  return (
    <Stack spacing={2} className="PaginationMain">
     
      {totRecord > 10 && (
        <div className="row">
          <div className="col-lg-6 col-sm-12 RecordsText">
            <span className="RecordsTextspan text-white">
              {/* {console.log("totalRecords", totalRecords)} */}
              {/* {totalRecords} */}
              Showing {totalRecords} of {totRecord} entries
            </span>
          </div>
          <div className="col-lg-6 col-sm-12 d-flex justify-content-end">
            <Pagination
              className="spassov"
              count={totalPages}
              variant="outlined"
              shape="rounded"
              page={currentPage}
              onChange={handlePageChange}
            />
          </div>
        </div>
      )}
    </Stack>
  );
}

export default UnityPagination;
