import { lazy } from "react";

const Login = lazy(() => import("../pages/LoginPage"));
const Signup = lazy(() => import("../pages/Signup.jsx"));
const Dashboard = lazy(() => import("../pages/Home"));
const AddEvent = lazy(() => import("../pages/Addevent"));
const LoginTime = lazy(() => import("../pages/Login"));
const TimeSheetReq = lazy(() => import("../pages/Timesheet/TimeSheetReq"));
const MyTimesheet = lazy(() => import("../pages/Timesheet/MyTimesheet.jsx"));
const EnterTimeSheet = lazy(() => import("../pages/Timesheet/EnterTimeSheet"));
const MyLeaveList = lazy(() => import("../pages/Permission/Myleavelist"));
const MyPermissionL = lazy(() => import("../pages/Permission/MyPermissionL"));
const Applyleave = lazy(() => import("../pages/Permission/Applyleave"));
const LeaveReq = lazy(() => import("../pages/Permission/LeaveReq"));
const PermissionReq = lazy(() => import("../pages/Permission/PermissionReq"));
const Policy = lazy(() => import("../pages/Permission/Policy"));
const AddProjects = lazy(() => import("../pages/MyProjects/AddProjects"));
const Expenses = lazy(() => import("../pages/MyProjects/Expenses"));
const TeamExpense = lazy(() => import("../pages/MyProjects/TeamExpense.jsx"));
const ViewProjects = lazy(() => import("../pages/MyProjects/ViewProjects"));
const MyTicket = lazy(() => import("../pages/MyTickets/MyTicket"));
const RaisedTickets = lazy(() => import("../pages/MyTickets/RaisedTickets"));
const TicketHistory = lazy(() => import("../pages/MyTickets/TicketHistory"));
const Designation = lazy(() => import("../pages/AddEmployee/Designation"));
const Employees = lazy(() => import("../pages/AddEmployee/Employees"));
const RolePermission = lazy(() =>
  import("../pages/AddEmployee/RolePermission")
);
const Roles = lazy(() => import("../pages/AddEmployee/Roles"));
const TeamType = lazy(() => import("../pages/AddEmployee/TeamType"));
const ViewTeamDetail = lazy(() =>
  import("../pages/MyProjects/ViewTeamDetails")
);
const TeamStatus = lazy(() => import("../pages/Permission/TeamStatus"));
const Overtime = lazy(() => import("../pages/Timesheet/Overtime"));
const OvertimeReq = lazy(() => import("../pages/Timesheet/OvertimeReq"));
const OvertimeReqView = lazy(() =>
  import("../pages/Timesheet/OvertimeReqView")
);
const TimesheetReqView = lazy(() =>
  import("../pages/Timesheet/TimesheetReqView.jsx")
);
const Userprofile = lazy(() => import("../pages/Profile"));
const Changepsw = lazy(() => import("../pages/Changepsw"));
const UpdateTime = lazy(() => import("../pages/UpdateTime/UpdateTime.jsx"));
const AllPermissionList = lazy(() =>
  import("../pages/Permission/AllPermission.jsx")
);
const LeaveType = lazy(() => import("../pages/Permission/LeaveType.jsx"));
const Employeeleave = lazy(() =>
  import("../pages/Permission/Employeeleave.jsx")
);
const Chartvariable = lazy(() => import("../pages/Permission/Chart.jsx"));
const ForgetPassword = lazy(() => import("../pages/ForgetPassword.jsx"));
const AddNewPassword = lazy(() => import("../pages/AddNewPassword.jsx"));
const Request = lazy(() => import("../pages/Timesheet/Requests.jsx"));
const SelectLeave = lazy(() =>
  import("../pages/Permission/EmployeeSelectLeave.jsx")
);
const UpdateLeave = lazy(() => import("../pages/Permission/UpdateLeave.jsx"));
const PendingTimesheet = lazy(() =>
  import("../pages/Timesheet/PendingTimesheet.jsx")
);
const ApprovedTimesheet = lazy(() =>
  import("../pages/Timesheet/ApprovedTimesheet.jsx")
);
const Notification = lazy(() => import("../pages/Notification.jsx"));
const EmployeeLogin = lazy(() => import("../pages/EmployeeLogin.jsx"));
const TicketHistoryTrail = lazy(() =>
  import("../pages/MyTickets/TicketHistoryTrail.jsx")
);
const DetailedTimesheet = lazy(() =>
  import("../pages/Timesheet/DetailedTimesheet.jsx")
);
const OverTimeCheck = lazy(() =>
  import("../pages/Timesheet/OverTimeCheck.jsx")
);

export {
  Login,
  Signup,
  Dashboard,
  AddEvent,
  EnterTimeSheet,
  TimeSheetReq,
  MyLeaveList,
  MyPermissionL,
  LoginTime,
  Applyleave,
  LeaveReq,
  PermissionReq,
  Policy,
  AddProjects,
  Expenses,
  ViewProjects,
  MyTicket,
  RaisedTickets,
  TicketHistory,
  Designation,
  Employees,
  RolePermission,
  Roles,
  TeamType,
  ViewTeamDetail,
  TeamStatus,
  Overtime,
  OvertimeReq,
  OvertimeReqView,
  Userprofile,
  Changepsw,
  UpdateTime,
  MyTimesheet,
  AllPermissionList,
  LeaveType,
  Employeeleave,
  Chartvariable,
  TimesheetReqView,
  TeamExpense,
  ForgetPassword,
  AddNewPassword,
  Request,
  // MyLeave,
  UpdateLeave,
  SelectLeave,
  PendingTimesheet,
  ApprovedTimesheet,
  Notification,
  EmployeeLogin,
  TicketHistoryTrail,
  DetailedTimesheet,
  OverTimeCheck,
};
