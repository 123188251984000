import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SidebarLink = styled(Link)`
  display: flex;
  color:lightgray;
  justify-content: space-between;
  font-family: 'Oxanium', sans-serif;
  align-items: center;
  padding: 10px;
  list-style: none;
  height: 50px;
  text-decoration: none;
  font-size: 16px;
  background:#1A1C24;

  &:hover {
    background: #0F1015;
    color:white;
    font-family: 'Oxanium', sans-serif;
    font-weight:bold;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid #0057FF;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const DropdownLink = styled(Link)`
  background: #1a1c24;
  height: 50px;
  padding-left: 3rem;
  display: flex;
  font-family: 'Oxanium', sans-serif;
  align-items: center;
  text-decoration: none;
  color: lightgray;
  font-size: 16px;

  &:hover {
    background: #0F1015;
    cursor: pointer;
  color:white;
  font-family: 'Oxanium', sans-serif;
  font-weight:bold;
  }
`;

const SubMenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <SidebarLink
        to={item.path}
        onClick={(e) => {
          if (item.subNav) {
            e.preventDefault(); // Prevent navigation
            showSubnav(); // Toggle visibility of submenus
          }
        }}
      >
        <div>
          {item.icon}
          <SidebarLabel>{item.title}</SidebarLabel>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
              ? item.iconClosed
              : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((subItem, index) => {
          return (
            <DropdownLink to={subItem.path} key={index}>
              {subItem.icon}
              <SidebarLabel>{subItem.title}</SidebarLabel>
            </DropdownLink>
          );
        })}
    </>
  );
};

export default SubMenu;